<template>
  <div class="footer-all" :style="{ height: isAddTop ? '20vw' : '16vw' }">
    <div
      :class="nowIndex == 0 ? 'footer-item footer-scale' : 'footer-item'"
      @click="toLink(0)"
    >
      <div class="iconBox">
        <img src="@/assets/footer/home1.png" alt="" />
        <img src="@/assets/footer/home2.png" alt="" />
      </div>
      <p>首页</p>
    </div>
    <div
      :class="nowIndex == 1 ? 'footer-item footer-scale' : 'footer-item'"
      @click="toLink(1)"
    >
      <div class="iconBox">
        <img src="@/assets/footer/classify1.png" alt="" />
        <img src="@/assets/footer/classify2.png" alt="" />
      </div>
      <p>分类</p>
    </div>
    <div
      :class="
        nowIndex == 2 ? 'footer-item footer-scale shop' : 'footer-item shop'
      "
      @click="toLink(2)"
    >
      <div class="iconBox">
        <img src="@/assets/footer/shop.png" alt="" />
        <img src="@/assets/footer/shop.png" alt="" />
      </div>
    </div>
    <div
      :class="nowIndex == 3 ? 'footer-item footer-scale' : 'footer-item'"
      @click="toLink(3)"
    >
      <div class="iconBox">
        <img src="@/assets/footer/msg1.png" alt="" />
        <img src="@/assets/footer/msg2.png" alt="" />
      </div>
      <p>消息</p>
      <div class="dot" v-if="isRed"></div>
    </div>
    <div
      :class="nowIndex == 4 ? 'footer-item footer-scale' : 'footer-item'"
      @click="toLink(4)"
    >
      <div class="iconBox">
        <img src="@/assets/footer/user1.png" alt="" />
        <img src="@/assets/footer/user2.png" alt="" />
      </div>
      <p>我的</p>
    </div>
  </div>
</template>

<style scoped lang="less">
.footer-all {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  z-index: 10;
  box-shadow: 0 -2px 2px #dddddd41;
  .footer-item {
    margin-top: 10px;
    width: 34px;
    height: 34px;
    position: relative;
    transition: all 0.3s;
    img {
      width: 20px;
      height: 20px;
      position: relative;
      z-index: 2;
      transition: all 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: unset;
    }
    img:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      img {
        width: 30px;
        height: 30px;
      }
    }
    p {
      font-size: 10px;

      font-weight: 400;
      color: #000;
      text-align: center;
      margin-top: 2px;
      line-height: 18px;
      transition: color 0.3s;
    }
  }
  .footer-active {
    p {
      color: #f04d2c;
    }
    img {
      transform: scale(1, 1);
    }
    img:nth-of-type(1) {
      opacity: 0;
    }
    img:nth-of-type(2) {
      opacity: 1;
    }
  }
  .footer-scale {
    .iconBox {
      img {
        transform: scale(0.8, 0.8) !important;
      }
    }
  }
  .iconBox {
    width: 20px;
    height: 20px;
    margin: 5px 7px 0 7px;
    position: relative;
  }
  .shop {
    width: 54px;
    height: 54px;
    margin-top: -5px;
    .iconBox {
      width: 54px;
      height: 54px;
      margin: -5px 2px 0 2px;
      border-radius: 50%;
      box-shadow: 0px 2px 12px rgba(36, 95, 179, 0.12);
      img {
        width: 54px;
        height: 54px;
      }
    }
  }
  .dot {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f04d2c;
  }
}
</style>

<script>
import { news_total } from "@/api/zh/message.js";
export default {
  data() {
    return {
      isAddTop: false,
      links: ["/", "/zh/classify", "/zh/cart", "/zh/msg", "/zh/account"],
      oldActive: 0,
      nowIndex: 0,
      isRed: false,
    };
  },
  methods: {
    getData() {
      news_total().then((res) => {
        if (res) {
          if (res.logistics_message == 0 && res.site_message == 0) {
            this.isRed = false;
          } else {
            this.isRed = true;
          }
        }
      });
    },
    isIPhoneX() {
      var u = navigator.userAgent;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        if (screen.height == 812 && screen.width == 375) {
          return true;
        } else {
          return false;
        }
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    toLink(index) {
      if (this.$route.path != this.links[index]) {
        // this.changeActive(index)
        this.$router.push({ path: this.links[index] });
      }
    },
    initTab() {
      // 判断路由
      this.links.forEach((el, index) => {
        if (el == this.$route.path) {
          this.nowIndex = index;
          this.changeActive(index);
        }
      });

      if (this.$route.path == "/zh") {
        this.nowIndex = 0;
        this.changeActive(0);
      }
    },
    changeActive(index) {
      let obj = document.getElementsByClassName("footer-item");
      // 首先添加缩放类
      let activeTimer = setTimeout(() => {
        // 去掉缩放 替换选中类
        obj[index].classList.remove("footer-scale");
        obj[index].classList.remove("footer-scale");
        obj[index].classList.add("footer-active");
        this.oldActive = index;
        clearTimeout(activeTimer);
      }, 300);
    },
  },
  created() {
    this.getData();
  },
  mounted() {

    if (this.isIPhoneX() && this.isWeiXin()) {
      this.isAddTop = true;
    }
  },
  watch: {
    '$route.path': {
      handler() {
        setTimeout(() => {
          this.initTab();
        }, 50);
      },
      immediate: true
    }
  }
};
</script>